import * as React from 'react';
import styled from 'styled-components';

const Container = styled.footer`
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: white;
`;

const Link = styled.a`
  margin: 10px;
  font-size: 90%;

  &:link {
    color: white;
    text-decoration: none;
  }

  &:visited {
    color: white;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Copyright = styled.p`
  color: #d0d0d0;
  font-size: 80%;
`;

const Footer = () => (
  <Container>
    <LinkContainer>
      <Link href="/flight-hazard/privacy-policy">Privacy Policy</Link>
      <Link href="/contact">Contact Us</Link>
    </LinkContainer>
    <Copyright>© 2021 Longpoint Studio</Copyright>
  </Container>
);

export default Footer;
