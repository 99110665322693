import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Helmet } from 'react-helmet';
import Footer from './footer';
import NavContainer from './navbar';

const Content = styled.main.attrs((props) => ({
  maxWidth: props.maxWidth || '75rem',
}))`
  padding: 0 50px;
  max-width: ${(props) => props.maxWidth};
  margin-left: auto;
  margin-right: auto;
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: -apple-system, Roboto, sans-serif, serif;
    color: white;
    background-color: rgb(51, 51, 51);
    font-size: 1.1rem;
  }
`;

const Layout = ({ children, maxWidth, title, rel, description }) => {
  return (
    <React.Fragment>
      <GlobalStyle />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description}></meta>
        <title>{title || 'Longpoint'}</title>
        <link rel="canonical" href={`https://longpoint.io${rel || ''}`} />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
      </Helmet>
      <NavContainer />
      <Content maxWidth={maxWidth} maxWidthMobile={3}>
        {children}
        <Footer />
      </Content>
    </React.Fragment>
  );
};

export default Layout;
