import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import LogoImage from '../images/svgs/logo.svg';

const hoverColor = '#1d9cee5c';

const Container = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-wrap: wrap;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
`;

const LinkContainer = styled.ul`
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 10px;
  overflow: hidden;
  background-color: #333;
  width: 100%;
`;

const NavItem = styled.li`
  float: left;
  border-radius: 4px;
  transition: 250ms;

  &:hover {
    background-color: ${hoverColor};
  }
`;

const Link = styled.a`
  display: block;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  cursor: pointer;
  color: white;

  &:hover {
    text-decoration: none;
  }

  &:visited {
    color: white;
  }

  /* Enable when more nav items are added */
  /* @media screen and (max-width: 700px) {
    display: ${(props) => (props.nohide ? 'block' : 'none')};
  } */
`;

// Enable when more nav items are added
// const DrawerIcon = styled.i`
//   display: none;

//   @media screen and (max-width: 700px) {
//     display: block;
//     margin-left: auto;
//   }
// `;

const Navbar = () => {
  return (
    <Container>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </Helmet>
      <LinkContainer>
        <NavItem>
          <Link nohide href="/">
            <LogoImage />
          </Link>
        </NavItem>
        <NavItem>
          <Link href="/flight-hazard">Flight Hazard</Link>
        </NavItem>
        {/* Enable when more nav items are added */}
        {/* <NavItem style={{ marginLeft: 'auto', fontSize: '1.8rem' }}>
          /* <DrawerIcon>
            <i class="fa fa-bars" />
          </DrawerIcon>
        </NavItem> */}
      </LinkContainer>
    </Container>
  );
};

export default Navbar;
